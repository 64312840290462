export enum ProjectTypes {
  OFFPLAN_PRIVATE_LAND = 'offplan_private_land',
  OFFPLAN_MOH_LAND = 'offplan_moh_land',
  READYMADE_PRIVATE_LAND = 'readymade_private_land',
  READYMADE_MOH_LAND = 'readymade_moh_land',
  LANDS_MOH_LAND = 'lands_moh_land',
  LANDS_PRIVATE_LAND = 'lands_private_land',
  OFFPLAN_LAND_PRIVATE_LAND = 'offplan_land_private_land',
  ADVANCED_SELF_CONSTRUCTION_LAND = 'advanced_self_construction',
  AUCTION = 'auction',
}
