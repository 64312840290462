export enum MarketUnitTypes {
  TOWNHOUSE = 'townhouse',
  APARTMENT = 'apartment',
  VILLA = 'villa',
  DUPLEX = 'duplex',
  FLOOR_THROUGH = 'floor_through',
  LAND = 'land',
  STUDIO = 'studio',
  ROOM = 'room',
  COMPOUND = 'compound',
  TOWER = 'tower',
  EXHIBITION = 'exhibition',
  OFFICE = 'office',
  WAREHOUSE = 'warehouse',
  BOOTH = 'booth',
  CINEMA = 'cinema',
  HOTEL = 'hotel',
  CAR_PARKING = 'car_parking',
  REPAIR_SHOP = 'repair_shop',
  TELLER = 'teller',
  FACTORY = 'factory',
  SCHOOL = 'school',
  HOSPITAL = 'hospital',
  STATION = 'station',
  FARM = 'farm',
  BUILDING = 'building',
  ELECTRIC_STATION = 'electric_station',
  REST_HOUSE = 'rest_house',
  TELECOM_TOWER = 'telecom_tower',
}
