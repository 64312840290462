export class RegaAdLicense {
  relationships: any = {};

  constructor(
    public ad_license_number?: string,
    public id_type?: string,
    public advertiser_id?: string,
    public brokerage_and_marketing_license_number?: string,
    public creation_date?: string,
    public end_date?: string,
    public advertiser_name?: string,
    public advertiser_mobile?: string,
    public deed_number?: string,
    public qr_code_url?: string,
    public property_type?: string,
    public property_usage?: any,
    public advertisement_type?: string,
    public plan_number?: string,
    public property_area?: string,
    public channels?: any,
    public borders_and_lengths?: string,
    public property_age?: string,
    public property_price?: string,
    public is_constrained?: boolean,
    public obligations_on_the_property?: string,
    public guarantees_and_their_duration?: string,
    public compliance_saudi_building_code?: boolean,
    public number_of_rooms?: string,
    public street_width?: string,
    public property_face?: string,
    public property_utilities?: string | string[],
    public width?: string,
    public height?: string,
    public location?: RegaLocation,
    public region_obj?: any,
    public city_obj?: any,
    public district_obj?: any,
    public sakani_district?: string
  ) {}

  get district(): string {
    return this.district_obj.name_ar || this.sakani_district;
  }

  get city(): string {
    return this.city_obj.name_ar;
  }

  get region(): string {
    return this.region_obj.name_ar;
  }
}

export interface RegaLocation {
  additional_number: string;
  building_number: string;
  city: string;
  city_code: string;
  district: string;
  district_code: string;
  latitude: string;
  longitude: string;
  postal_code: string;
  region: string;
  region_code: string;
  street: string;
}

export class RegaAdLicenseShorterned {
  relationships: any = {};
  constructor(
    public ad_license_number?: string,
    public decrypt_advertiser_id?: string,
    public deed_number?: string,
    public is_valid?: string,
    public id_type?: string
  ) {}
}
