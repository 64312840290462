export const environment = {
  production: true,
  recaptchaSiteKey: '6LcponUUAAAAAP-4-9-jgBXo23HKOWc4rQEpHRtn',
  recaptchaV3SiteKey: '6LchigkpAAAAAJRfq8dJWPyBEXZ6YMBxU-3sqra9',
  appMode: 'development',
  lokaliseS3Path: {
    en: 'https://ruh-s3.bluvalt.com/i18n/locales/en_sakani_v4_fe.json',
    ar: 'https://ruh-s3.bluvalt.com/i18n/locales/ar_sakani_v4_fe.json',
  },
  lokaliseCExpiredInMins: 1,
  cookieDomain: 'housing.sa',
  cookieSecure: true,
  cmsSiteId: 'sakani-cms-dev',
  API_URL: '',
  authPath: 'https://dev-sakani-v3.sakani.housing.sa/auth',
  azmPaymentCallback: 'https://dev-sakani-v3.sakani.housing.sa/app/booking/offplan/azm-payment-callback',
  azmPaymentExtendPriceQuotationCallback:
    'https://dev-sakani-v3.sakani.housing.sa/app/extend-price-quotation/azm-payment-callback',
  firebaseDebugMode: true,
  firebaseConfig: {
    apiKey: 'AIzaSyBXtpliBNy0Tr-jDXrfHltJYPjLyoMsAlw',
    authDomain: 'sakani-v4-local.firebaseapp.com',
    projectId: 'sakani-v4-local',
    storageBucket: 'sakani-v4-local.appspot.com',
    messagingSenderId: '414518424702',
    appId: '1:414518424702:web:d6008ba452d0027e32142f',
    measurementId: 'G-GE2RVPWNJ1',
  },
  azmCallback: 'https://dev-sakani-v3.sakani.housing.sa/app/payment-callback',
  azmPaygate: 'https://checkout.housingapps.sa',
  esriApiKey: 'AAPKdf709649ac2c45f4830c6bd0ab0ad137niclppc9164ctWHgk2XNyB-0pLJi83pazviwfGY_9zLKgxW5G-jSX35-KjJjeQRW',
  nhcPath: 'https://dev-nhc-sakani.housingapps.sa',
  sakaniPath: 'https://dev-sakani-v3.sakani.housing.sa/app',
  campaignMode: false,
};
